import React from "react";
import "./Home.css";
import iptv from "../images/iptv.png";
import Androidtv from "../images/Androidtv.png";
import Appletvlogo from "../images/Appletvlogo.png";
import LGlogo from "../images/LGlogo.png";
import iptvlogo from "../images/iptvlogo.png";
import Youtubelogo from "../images/Youtubelogo.png";

import { FaSearch } from "react-icons/fa";
import { MdFavorite } from "react-icons/md";
import { FaLanguage } from "react-icons/fa";
import { FaAudioDescription } from "react-icons/fa";
import { MdSupervisorAccount } from "react-icons/md";
import { GrGallery } from "react-icons/gr";
import { FaUserLock } from "react-icons/fa";
import { RxResume } from "react-icons/rx";

const Home = () => {
  return (
    <div>
      <div className="home_header">
        <div className="home-header">
          <h1>MasaPlayer, The Best Application on Tv</h1>
          <h2>
            Masaplayer is the most advanced and user friendly TV media player.
            With Masaplayer you will enjoy your favorite media  on your
            smart TV.
          </h2>
        </div>
        <div className="home_icon">
          <img src={iptv} alt="home_icon" />
        </div>
      </div>
      <div className="header_logos">
        <div className="img_wrapper">
          <img src={Androidtv} alt="androidtv" className="img" />
        </div>
        <div className="img_wrapper">
          <img src={Appletvlogo} alt="appletv" className="img" />
        </div>
        <div className="img_wrapper">
          <img src={LGlogo} alt="LGtv" className="img" />
        </div>
        <div className="img_wrapper">
          <img src={iptvlogo} alt="iptv" className="img" />
        </div>
        <div className="img_wrapper">
          <img src={Youtubelogo} alt="smarttv" className="img" />
        </div>
      </div>
      <div className="tvplayer_description">
        <h1>THE NUMBER #1 SMART TV MEDIA PLAYER</h1>
        <div className="underline">
            <span className="whiteline"></span>
            <span className="blueline"></span>
            <span className="whiteline"></span>
          </div>
        <div className="tvplayer_description_about">
          <div className="tvplayer-desc" col-6>
            <ul>
              <li>
                <p>Modern user interface design</p>
              </li>
              <li>
                <p>Fast Zapping</p>
              </li>
              <li>
                <p>Custom theme</p>
              </li>
              <li>
                <p>Multi-Device Management platform</p>
              </li>
              <li>
                <p>Listen to radio freely (30000 stations)</p>
              </li>
              <li>
                <li>
                  <p>
                    Live Scores for multiple sport, Football, Basketball ...etc
                  </p>
                </li>
                <p>Watch free short video, Tiktok, 9Gag</p>
              </li>
            </ul>
          </div>
          <div className="tvplayer-desc-right" col-6>
            <p>
              Masaplayer is a media player for smart Tvs, including Samsung TVs
              under Orsay and Tizen, LG TVs running webOS and Netcast,
              Masaplayer app allow end-users of Xtream Code panel or
              similar panels to watch their contents via their API, Duplecast
              allow users to watch Live, Vod (Video On Demand), Series
              in all smart Tvs with easy tasks. Masaplayer is multi-languages and
              them-able APP . Masaplayer also support m3u playlist
              created / generated from other Smart TV systems.
            </p>
          </div>
        </div>
      </div>
      <div className="features_description">
        <div className="features_header">
          <h1>FEATURES OVERVIEW</h1>
          <div className="underline">
            <span className="whiteline"></span>
            <span className="blueline"></span>
            <span className="whiteline"></span>
          </div>
          <p>Enjoy your Masaplayer, Now</p>
        </div>
        <div className="features_description_about">
          <div className="card">
            <FaSearch size={32} />
            <h2>Quick Search</h2>
             <p>Quickly search for items in playlists</p>
          </div>
          <div className="card">
            <MdFavorite size={32} />
            <h2>Favoris List</h2>
             <p>Allows creation and customization of favorite groups for quick access</p>
          </div>
          <div className="card">
            <FaLanguage size={32} />
            <h2>Multi-Languages</h2>
             <p>English, French, Spanish, Russain, Arabic and more...!</p>
          </div>
          <div className="card">
            <FaAudioDescription size={32} />
            <h2>Audio tracks and subtitles</h2>
            <p>Supports selection of audio tracks and embedded subtitles</p>
          </div>
          <div className="card">
            <MdSupervisorAccount size={32} />
            <h2>Device Account</h2>
            <p>Device Account support, Add/Delete as you want.</p>
          </div>
          <div className="card">
            <GrGallery size={32} />
            <h2>User friendly</h2>
            <p>Allows seek to precise position, you can drag the time slider to the precise position</p>
          </div>
          <div className="card">
            <FaUserLock size={32} />
            <h2>Parental locked</h2>
            <p>You can create your own Parental Locking PIN.</p>
          </div>
          <div className="card">
            <RxResume size={32} />
            <h2>Resume watching</h2>
            <p>Saves recently played lists and resume playback from last saved position</p>
          </div>
        </div>
      </div>
      <div className="home_footer">
        <p>© Copyright MASAPLAYER.COM All Rights Reserved</p>
        <p>Designed by SAZPIN SOFTWARE</p>
      </div>
    </div>
  );
};
export default Home;
