import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import News from "./components/News/News";
// import Instruction from "./components/Instruction/Instruction";
import MyList from "./components/MyList/MyList";
import Activation from "./components/Activation/Activation";
import Support from "./components/Support/Support";
import ManagePlaylist from "./components/Manageplaylist/Manageplaylist";

import Navbar from "./components/Navbar/Navbar";
import Contactus from "./components/Contactus/Contactus";
import PlaylistComponent from "./components/Submit/Playlist";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/AboutUs" element={<News />}></Route>
        {/* <Route path="/instruction" element={<Instruction />}></Route> */}
        {/* <Route path="/myList" element={<MyList />}></Route> */}
        <Route path="/activation" element={<Activation />}></Route>
        <Route path="/support" element={<Support />}></Route>
        <Route path="/contactus" element={<MyList />} />
        <Route path="/manageplaylist" element={<ManagePlaylist />}></Route>
        <Route path="/dashboard" element={<PlaylistComponent />}></Route>
      </Routes>
    </Router>
  );
};

export default App;
