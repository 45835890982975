import React from "react";
import "./Support.css";
const Support = () => {
  return (
    <div>
      <div className="container">
        <div className="support_container">
          <div className="contact_header">
            <h1>Frequently Asked Questions</h1>
            <div class="underline">
              <span class="whiteline"></span>
              <span class="blueline"></span>
              <span class="whiteline"></span>
            </div>
            <div class="question_info">
              <h3>
                Does{" "}
                <a href="https://masaplayer.com/mylist"> MASA PLAYER TEAM</a>{" "}
                contain any channels? Where can I get a good playlist?
              </h3>
              <p>
                MASA PLAYER and any administrator does not help you to getting
                find a good playlist. Also we dont provide any kind of
                playlists. We are not responsible for the content uploaded to
                our APP.
                <br />
                Please dont buy the app when you dont have any playlist or Media
                for the APP, because no channels are included after the
                activation in this app. You payment will not be refunded if you
                buy it without having any list or something is not workng
              </p>
            </div>
            <div class="question_info">
              <h3>
                My MAC address has changed after I switched to another
                connection type.{" "}
              </h3>

              <p>
                Each TV has 2 MAC addresses (1st is WiFi, 2nd is Ethernet) and a
                2nd MAC is activated automatically after you switch to another
                connection type and restart the app. MAC address is unique for
                every TV and cannot be changed manually.
              </p>
            </div>
            <div class="question_info">
              <h3>Why is the app freezing on start.</h3>

              <p>
                Because the app everytime when you start it, need to load the
                list from your source or providers server.
              </p>
            </div>
            <div class="question_info">
              <h3>
                Can i change the background image of the
                <a href="https://flixapp.tv/"> APP</a>?
              </h3>

              <p>
                Yes, under settings you can find other themes. There are 3
                themes for you!
              </p>
            </div>
            <div class="question_info">
              <h3>
                Why is the app not working when the{" "}
                <a href="https://masadroid.com/instruction">PLAYLIST</a> worked
                on my computer?
              </h3>

              <p>
                This can caused because of the ISP lock, if your computer is in
                a different Network. But when the network is same as your
                computer and it is not working it can be caused because of your
                TV`s supported format. Not every Smart-TV support all contents.
                Every TV model are totally different or may not support specific
                stream formats!
              </p>
              <br />
              <p>Below you can see the supported formarts from Smart TV`s: </p>
              <br />
              <ul>
                <li>
                  <a href="https://developer.samsung.com/smarttv/develop/specifications/general-specifications.html">
                    Samsung supported formats and codecs
                  </a>
                </li>
                <li>
                  <a href="https://webostv.developer.lge.com/discover/specifications/supported-media-formats">
                    LG webOS supported formats and codecs
                  </a>
                </li>
                <li>
                  <a href="https://developer.android.com/guide/topics/media/platform/supported-formats">
                    Android platform supported formats and codecs
                  </a>
                </li>
              </ul>
            </div>
            <div class="question_info">
              <h3>Why i can not start the APP?</h3>

              <p>
                This is maybe because of your internet connection, please check
                at first your internet connection befor you contact your
                provider. Many times it is caused because of the connection and
                not because of the provider!
              </p>
            </div>
            <div class="question_info">
              <h3>Do the MASA PLAYER have an EPG-SYSTEM?</h3>

              <p>
                No, the app didn`t have an EPG system integrated but if oyur
                provider has an EPG - READY sysem then it would work with our
                platforms.
              </p>
            </div>
          </div>
        </div>

        {/* <div className="footer">
        <a href="/">Terms and Conditions </a>
        <a href="/">Privacy and Policy</a>
      </div> */}
      </div>
      <div className="support_footer">
        <p>© Copyright MASAPLAYER.COM All Rights Reserved</p>
        <p>Designed by SAZPIN SOFTWARE</p>
      </div>
    </div>
  );
};

export default Support;
