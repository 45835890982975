import React, { useState } from 'react';
import './Playlist.css';
import Modal from 'react-modal';

const IPTVPage = () => {
  const [activeTab, setActiveTab] = useState('Playlist'); // Default active tab
  const [playlists, setPlaylists] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newPlaylist, setNewPlaylist] = useState({ name: '', url: '' });

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPlaylist((prevPlaylist) => ({ ...prevPlaylist, [name]: value }));
  };

  const handleAddPlaylist = () => {
    if (newPlaylist.name && newPlaylist.url) {
      setPlaylists((prevPlaylists) => [...prevPlaylists, newPlaylist]);
      setNewPlaylist({ name: '', url: '' });
      closeModal(); // Close the modal after adding a playlist
    }
  };

  return (
    <div className="iptv-container">
      <div className="tabs">
        <div
          className={`tab ${activeTab === 'Playlist' ? 'active' : ''}`}
          onClick={() => handleTabClick('Playlist')}
        >
          Playlist Management
        </div>
        <div
          className={`tab ${activeTab === 'Activation' ? 'active' : ''}`}
          onClick={() => handleTabClick('Activation')}
        >
          Device Activation
        </div>
        <div
          className={`tab ${activeTab === 'Logout' ? 'active' : ''}`}
          onClick={() => handleTabClick('Logout')}
        >
          Logout
        </div>
      </div>
      <div className="content">
        <h2>{activeTab === 'Playlist' && 'Playlist Management'}</h2>
        {activeTab === 'Playlist' && (
          <div>
            <button onClick={openModal}>Add Playlist</button>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>URL</th>
                </tr>
              </thead>
              <tbody>
                {playlists.map((playlist, index) => (
                  <tr key={index}>
                    <td>{playlist.name}</td>
                    <td>{playlist.url}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Modal for adding a playlist */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Playlist Modal"
      >
        <h2>Add Playlist</h2>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={newPlaylist.name}
            onChange={handleInputChange}
          />
        </label>
        <label>
          URL:
          <input
            type="text"
            name="url"
            value={newPlaylist.url}
            onChange={handleInputChange}
          />
        </label>
        <button onClick={handleAddPlaylist}>Add Playlist</button>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};


export default IPTVPage;
