// ManagePlaylist.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Manageplaylist.css';

function ManagePlaylist() {
  const [macAddress, setMacAddress] = useState('');
  const [deviceKey, setDeviceKey] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    if (event.target.name === 'macaddress') {
      setMacAddress(event.target.value);
    }
    if (event.target.name === 'devicekey') {
      setDeviceKey(event.target.value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      // Validate macAddress format (must contain colons)
      if (!macAddress.includes(':')) {
        setErrorMessage('Check MAC Address Format');
        return;
      }
  
      // Remove colons from macAddress
      const cleanedMacAddress = macAddress.replace(/:/g, '');
  
      console.log('Sending login request...', cleanedMacAddress, deviceKey);
      
      const response = await axios.post('http://localhost:5000/login', {
        device_id: cleanedMacAddress,  // Match with backend field device_id
        device_code: deviceKey,        // Match with backend field device_code
      });
  
      console.log('Login response:', response);
  
      if (response.status === 200) {
        const { token } = response.data;
        localStorage.setItem('token', token);
        navigate('/dashboard');
      } else if (response.status === 401) {
        setErrorMessage('Invalid MAC address or device key');
      } else {
        setErrorMessage(response.data.error || 'Authentication failed');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage(`An unexpected error occurred: ${error.message}`);
    }
  };
  
  

  return (
    <div className="form_container">
      <form onSubmit={handleSubmit}>
        <div className="manageplaylist_loginform">
          <label> MAC ADDRESS</label>
          <input type="text" name="macaddress" value={macAddress} onChange={handleChange} />
        </div>

        <div className="manageplaylist_loginform">
          <label> DEVICE KEY</label>
          <input type="text" name="devicekey" value={deviceKey} onChange={handleChange} />
        </div>

        <button type="submit" className="submit">
          Submit
        </button>

        {errorMessage && <p className="error_message">{errorMessage}</p>}
      </form>
    </div>
  );
}

export default ManagePlaylist;
