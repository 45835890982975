import React from "react";
import "./MyList.css";

const MyList = () => {
  return (
    <div className="mylist_content">
      <div className="mylist_content_card">
        <div className="contact_header">
          <h1>CONTACT US</h1>
          <div class="underline">
            <span class="whiteline"></span>
            <span class="blueline"></span>
            <span class="whiteline"></span>
          </div>
          <div class="contact_content">
            <h2>Complete Office</h2>
            <ul>
              <li>Company Name:Ahmad Mibayyad IT Services Co.LLC</li>
              <li>Country-UAE Dubai, United Arab Emirates Al Mararr 625 </li>
              <li>P.O Box No.625</li>
              <li>Contact Number 00971527595090</li>
              <li>Email Address Masaplayer.dev@gmail.com</li>
            </ul>
          </div>
        </div>

        <h1>Playlist upload for MASA PLAYER App</h1>
        <div class="underline">
          <span class="whiteline"></span>
          <span class="blueline"></span>
          <span class="whiteline"></span>
        </div>
        <p>
          #EXTINF:-1 tvg-ID="EPG-CODE" tvg-name="CHANNELNAME"
          tvg-logo="LOGO-URL" group-title="Italia",IT: Channel.
          http://test/live/123.ts
        </p>
        <p>Default PASSWORT for PARENTAL CONTROL is: 0000</p>
        <p>
          Download the APP for Amazon Fire TV:{" "}
          <a href="/">app.masaplayer.com</a>
        </p>

        <div className="centered">
          <p className="para">We are a pure media player</p>
          <p className="para1">We do not offer channels or movies</p>
          <p className="para2">
            We are updating our web server because of this it cause sometimes
            issues please be patient. The webseite can be slow or the APP.
          </p>
          <img src="/" alt="" />
        </div>
        <form className="mylist_form">
          <div className="input-boxes">
            <span className="left_inputbox">
              <label className="mac">Mac Address:</label>
              <input
                type="text"
                className="mac-inputbox"
                placeholder="00-aa-bb-cc-dd-11"
              />
            </span>
            <div className="inputform">
              <span className="inputtype">
                <label htmlFor="" className="label_name">
                  Name:
                </label>
                <input type="text" className="text_input" />
              </span>
              <span className="inputtype">
                <label htmlFor="" className="url">
                  URL:
                </label>
                <input type="url" className="url-input" />
              </span>
            </div>
            <i class="fa-solid fa-plus"></i>
          </div>
          <button className="send">Send</button>
        </form>
        <form className="deleteForm">
          <div className="left">
            <label>Mac Address:</label>
            <input type="text" placeholder="00-aa-bb-cc-dd-11" />
          </div>
          <div className="delete_btn">
            <button>Delete</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MyList;
