import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../images/logo.png";
import useWindowDimensions from "../../hooks/useDimensionshook";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
const {width} = useWindowDimensions();
useEffect(()=>{
setIsMobile(false)
},[width > 780])
  return (
    <nav className="navbar">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <ul
        className={isMobile ? "nav-links-mobile" : "nav-links"}
        onClick={() => setIsMobile(false)}
      >
        <Link to="/home" className="menu_text">
          <li>Home</li>
        </Link>
        {/* <Link to="/instruction" className="menu_text">
          <li>Instruction</li>
        </Link> */}
        {/* <Link to="/myList" className="menu_text">
          <li>MyList</li>
        </Link> */}
        <Link to="/activation" className="menu_text">
          <li>Activation</li>
        </Link>
        <Link to="/support" className="menu_text">
          <li>Support</li>
        </Link>
        <Link to="/AboutUs" className="menu_text">
          <li>AboutUs</li>
        </Link>
        <Link to="/contactus" className="menu_text">
          <li>Contactus</li>
        </Link>
        <Link to="/manageplaylist" className="manageplaylist">
          <li className="manage_button">ManagePlaylist</li>
        </Link>
      </ul>

      <button
        className="mobile-menu-icon"
        onClick={() => setIsMobile(!isMobile)}
      >
        {isMobile ? (
          <i className="fas fa-times icon"></i>
        ) : (
          <i className="fas fa-bars icon"></i>
        )}
      </button>
    </nav>
  );
};

export default Navbar;
