import React from "react";
import "./News.css";

const News = () => {
  return (
    <div>
      <div className="news-content">
        <div className="news_content_card_header">
          <h1>About Us</h1>
          <div class="underline">
            <span class="whiteline"></span>
            <span class="blueline"></span>
            <span class="whiteline"></span>
          </div>
          <p>
            Established in 2014, Masa player is one of the leading companys
            specializing in designing and manufacturing HD media players,
            Android tv box, OTT boxes and smart projectors. With independent
            R&D, SMT and assemble production line, the company provides
            effective OEM and ODM services to many IPTV platforms and tv box
            wholesales stores and retails worldwide.Having been in tv box Media
            player field many years, our tv box have been sold all over the
            world especially to Europe and North America.
            <br />
            We our tied up with top manufacturing factory in China.
            <br />
            All of our tv box are strictly compliant with the highest
            International standards.We have obtained TUV, SGS and HDMI
            certificates in the past years. Masa tv IVS Boxâ€™s component
            suppliers are top brands such as Realtek, Amlogic, Nanya, Hynix,
            Samsung, NXP and Ampak. With an excellent quality guarantee and a
            continuous software support service, the company has won high praise
            and gained a good reputation with our customers.
            <br />
            <br />
            Offering perfect performance and easy operation is our mission. We
            are always doing our best to let all customers enjoy the highest
            quality video experience. Watch what you want by Masa player!
          </p>
        </div>
        <div className="news-content-card">
          <h3>Our version of Samsung Tizen Tv has been updated</h3>
          <h4>New features;</h4>
          <ul>
            <li>9 different language options.</li>
            <li>Channel groups have been brought to the opening screen.</li>
            <li>Page up, Page down feature improved.</li>
            <li>
              Movie, TV series information tab has been added to the subband
              while watching a movie or TV show
            </li>
            <li>
              In the details section of the movie or TV series, the category of
              the current content was specified.
            </li>
            <li>A functional menu was developed in the live TV section</li>
          </ul>
          <p>Corrections;</p>
          <ul>
            <li> Reload time shortened.</li>
            <li> In-app performance improvements were made.</li>
            <p>Please update your application.</p>
          </ul>
        </div>

        <div className="news-content-card">
          <h3>Our version of ANDROID Tv and Mobile has been updated</h3>
          <p>New features;</p>
          <ul>
            <li> 9 different language options.</li>

            <li> Channel groups have been brought to the opening screen.</li>

            <li> Page up, Page down feature improved.</li>

            <li>
              Movie, TV series information tab has been added to the subband
              while watching a movie or TV show.
            </li>

            <li>
              In the details section of the movie or TV series, the category of
              the current content was specified.
            </li>

            <li> A functional menu was developed in the live TV section.</li>
          </ul>
          <p>Corrections;</p>
          <ul>
            <li> Reload time shortened.</li>

            <li> In-app performance improvements were made.</li>
          </ul>
          <p>Please update your application. </p> <br />
          <a href="https://masaplayer.com/mylist">MASA PLAYER TEAM</a>
          <br />
        </div>
        <div className="news-content-card">
          <h3>Our App For APPLE TV is online</h3>
          <p>
            You can find and download the Flix APP application by typing flix in
            the search section of your Apple TV application store.
          </p>
          <br />
          <a href="https://masaplayer.com/mylist">MASA PLAYER TEAM</a>
          <br />
        </div>

        <div className="news-content-card">
          <h3>Our App for IOS is online</h3>
          <p>
            Hello everybody, our app is now online on Apple APP-STORE, you can
            download it from Apple store and pay it directly with apple pay.
          </p>
          <br />
          <a href="https://masaplayer.com/mylist">MASA PLAYER TEAM</a>
        </div>

        <div className="news-content-card">
          <p>
            Hello everybody, we updated the app and because of this maybe you
            will see another fixed mac adress.
          </p>

          <p>
            Please send us your old mac adress and the new mac adress per mail
            to: info@flixapp.
          </p>

          <p>like old: 00:00:00:00:00..</p>

          <p>new : 11:11:11:11:11..</p>

          <p>We will active the new mac adress of your app. </p>

          <p>Update: </p>
          <ul className="list">
            <li>fixed Mac-Adress</li>
            <li>loading speed improved</li>
            <li>added load EPG (if your provider provie any EPG)</li>
            <li>Movies and series loading improved</li>
            <li>M3u files support</li>
            <li>flussonic panel owner should upload the Playlist as M3u</li>
            <li>adding channels, movies, series more then 100 Favorites</li>
          </ul>
          <br />
          <a href="https://masaplayer.com/mylist">MASA PLAYER TEAM</a>
        </div>

        <div className="news-content-card">
          <p> Hello everybody,</p> <br />
          <p>our app is now online for Samsung model group 18-19</p>
          <br />
          <p>We are now working on updates.</p>
          <br />
          <a href="https://masaplayer.com/mylist">MASA PLAYER TEAM</a>
        </div>

        <div className="news-content-card">
          <p>Hello everybody,</p>
          <br />
          <p>
            our app is now online for Android TV (sony, phillips and for others
            too).
          </p>
          <br />
          <a href="https://masaplayer.com/mylist">MASA PLAYER TEAM</a>
        </div>

        <div className="news-content-card">
          <p>
            Please do not send us emails asking for channels in the application.
          </p>
          <br />
          <p>Please check the most frequently asked questions (FAQs).</p>
          <br />
          <p>
            All emails requesting free channel packets are ignored and
            unanswered.
          </p>
          <br />
          <p>How they work</p>
          <br />
          <p>
            Upload your m3u list from your provider and connect your list with
            your FLIX APP MAC number.
          </p>
          <br />
          <p>Enjoy now your TV experience.</p>
          <br />
          <a href="https://masaplayer.com/mylist">MASA PLAYER TEAM</a>
        </div>

        <div className="news-content-card">
          <p>We are done with the latest update for 2018 models TV online.</p>
          <p>
            We are now waiting for Samsung to bring the latest update version
            1.0.1 for 2018-2020 model TVs online.
          </p>
          <br />
          <ul>
            <li>Change language at VOD</li>
            <li>Multiple playlists (1-2-3-4)</li>
            <li>Reload for M3U lists (Refresh list)</li>
            <li>
              delete category function for stream-categories, VOD-categories and
              SERIEs-categories (you can find it in Settings on HOME-SCREEN)
            </li>
            <br />
          </ul>

          <p>The latest update fixes some issues.</p>
          <br />
          <a href="https://masaplayer.com/mylist">MASA PLAYER TEAM</a>
        </div>
        <div className="footer">
          <a href="/">Terms and Conditions </a>
          <a href="/">Privacy and Policy</a>
        </div>
      </div>
      <div className="news_footer">
        <p>© Copyright MASAPLAYER.COM All Rights Reserved</p>
        <p>Designed by SAZPIN SOFTWARE</p>
      </div>
    </div>
  );
};

export default News;
