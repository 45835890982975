import React from "react";
import "./Activation.css";

const Activation = () => {
  return (
    <div className="activation_content">
      <div className="activation_content_card">
        <p className="p_class">
          Users can activate their Smart TVs and supported devices after paying
          a one-time fee of 7,49 EUR for each TV or device.
          <br />
          If you do not want to get activation delays, it is advisableto not
          wait for the trial version to expire if everything is working out. You
          can use the panel below for activation of your TV or device instantly
          in automatic mode. Once your trial period gets expired, you will lose
          your playlist from your device plus the server. <br />
          <span className="please_note_class">Please Note!</span>
          Do not make a payment if you haven`t got any channels with which you
          will use the application or if there are any other problems. The
          payment will not activate any sort of channels and you will also not
          be liable for a refund.
          <br /> You will have to add your own preferred channels as the
          activation won`t provide any. We cannot provide any information
          regarding where to get a working playlist and about channels even
          post-activation...
        </p>
        <h1>Activation</h1>

        <form className="deleteForm">
          <div className="left">
            <label>Mac Address:</label>
            <input type="text" placeholder="00-aa-bb-cc-dd-11" />
          </div>
          <div className="Check_btn">
            <button>Check</button>
          </div>
        </form>
      </div>

      {/* <div className="footer">
        <a href="/">Terms and Conditions </a>
        <a href="/">Privacy and Policy</a>
      </div> */}
    </div>
  );
};

export default Activation;
